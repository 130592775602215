import { ReactNode, useEffect, useState } from "react"
import { Modal as BaseModal } from "antd"
import WarningOverlay from "./WarningOverlay"
import useHtmlId from "./utils/useHtmlId"

type WarnOnCloseResponse = {
  warnOrSetInactive: () => void
  cancel: () => void
  showWarning: boolean
}

function useDelayedMount(isActive: boolean) {
  const [mount, setMount] = useState(isActive)
  useEffect(() => {
    const t = setTimeout(() => setMount(isActive), isActive ? 20 : 100)
    return () => {
      clearTimeout(t)
    }
  }, [isActive])

  return mount
}

type Props = {
  isActive: boolean
  setInactive: () => void
  warnOnCloseProps: WarnOnCloseResponse
  children?: ReactNode
}

export function Modal({ isActive, setInactive, warnOnCloseProps, children }: Props) {
  const mount = useDelayedMount(isActive)
  const id = useHtmlId()

  return (
    <BaseModal
      open={isActive}
      onOk={warnOnCloseProps.warnOrSetInactive}
      className={id}
      onCancel={warnOnCloseProps.warnOrSetInactive}
      closable={false}
      destroyOnClose
      footer={null}
    >
      <style>{`
.${id}.ant-modal {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
}

.${id} .ant-modal-content {
  height: 100vh;
  width: 100vw;
  padding: 0 !important;
  border-radius: 0 !important;
}
.${id} .ant-modal-body {
  height: 100%;
}
      `}</style>
      <div className="vflex h-full max-h-full isolate">{mount ? children : null}</div>
      {warnOnCloseProps.showWarning && (
        <WarningOverlay
          closeForm={() => {
            setInactive()
            warnOnCloseProps.cancel()
          }}
          dismissWarning={warnOnCloseProps.cancel}
        />
      )}
    </BaseModal>
  )
}
