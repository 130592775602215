export namespace BaseInputUtils {
  export type Props<T> = {
    value: T
    update?: (newValue: T) => void
    focus?: () => void
    blur?: () => void
    disabled?: boolean
    hidden?: boolean
    error?: string | null
  }

  export const ClassNames =
    "text-[14px] md:text-[14px] font-medium md:font-medium leading-[19.4px] md:leading-[17px] placeholder:text-th-text-hint"
}
