import { useEffect, useState } from "react"

export default function useGeometry(el: HTMLElement | null) {
  const [size, setSize] = useState(
    el
      ? {
          h: el.clientHeight,
          w: el.clientWidth,
          scrollH: el.scrollHeight,
          scrollW: el.scrollWidth,
        }
      : null,
  )

  useEffect(() => {
    const doResize = () => {
      setSize(
        el
          ? {
              h: el.clientHeight,
              w: el.clientWidth,
              scrollH: el.scrollHeight,
              scrollW: el.scrollWidth,
            }
          : null,
      )
    }
    window.addEventListener("resize", doResize)

    return () => {
      window.removeEventListener("resize", doResize)
    }
  }, [el])

  return size
}
