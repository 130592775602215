import { FC } from "react"
import { Q } from "cb"
import { Form } from "./Form"
import { initContext, OpenerProps } from "./queryContext"
import { FormUI, useFormContext } from "./openerContext"

type FormControllerProps<
  TQueryProps extends object,
  TQueryset extends object = Record<string, never>,
> = {
  useQueries?: (props: TQueryProps) => Q.Queryset<TQueryset>
}

export function Controller<TQueryProps extends object, TQueryset extends object = {}>(
  props?: FormControllerProps<TQueryProps, TQueryset>,
) {
  const useQueries = props?.useQueries ?? (() => Q.group({}) as any)
  const propsContext = initContext(useQueries)

  const useContext = (): TQueryProps & TQueryset & { setInactiveWithoutWarning: () => void } => {
    const ctx = propsContext.use()
    const openerContext = useFormContext()
    return { ...ctx, ...openerContext }
  }

  return {
    useContext,
    Form: (Comp: FC<OpenerProps>, useUi?: () => FormUI) =>
      propsContext.wrap<OpenerProps & TQueryProps>(Form(Comp, useUi)),
  }
}
