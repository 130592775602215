import { ReactNode, useEffect, useRef } from "react"

type Props = {
  disabled?: boolean
  children: ReactNode
  position?: "start" | "end"
}
export default function ScrollOnMount({ disabled, children, position = "start" }: Props) {
  const myRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!disabled) {
      // TODO: this delay should probably be parametrizable
      const t = setTimeout(() => {
        myRef.current?.scrollIntoView({ behavior: "smooth", block: position, inline: position })
      }, 180)

      return () => {
        clearTimeout(t)
      }
    } else {
      return () => {}
    }
  }, [myRef, disabled, position])

  return <div ref={myRef}>{children}</div>
}
