import { ReactNode, useState } from "react"
import { Colors } from "compass-local/utils/colors"
import AnimatedHeight from "react-animate-height"
import { cn } from "msutils/classnames"
import Typography from "compass/data/Typography"

type Props = {
  active: boolean
  message: string | null
  color?: keyof typeof Colors
  icon?: ReactNode
}

const DURATION = 100

export default function Annotation({ active, icon, color, message }: Props) {
  const [showInDom, setShowInDom] = useState(active && message !== null)

  return (
    <AnimatedHeight
      height={active && message !== null ? "auto" : 0}
      duration={DURATION}
      animateOpacity
      className={cn(!showInDom && "absolute")}
      onHeightAnimationEnd={() => {
        if (!active || message === null) setShowInDom(false)
      }}
      onHeightAnimationStart={() => {
        if (active && message !== null) setShowInDom(true)
      }}
    >
      <div className={cn("flex gap-2 items-center", Colors[color ?? "gray"])}>
        {icon}
        <Typography>{message}</Typography>
      </div>
    </AnimatedHeight>
  )
}
