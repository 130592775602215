import { ReactNode, useEffect, useState } from "react"
import { Drawer as BaseDrawer } from "antd"
import useScreenSize from "compass/theme/useScreenSize"
import useGeometry from "compass-local/utils/useGeometry"
import { LayoutFlagContextProvider } from "compass/layout/LayoutFlagContext"
import { useCompassContext } from "./CompassProvider/utils"
import WarningOverlay from "./WarningOverlay"

type WarnOnCloseResponse = {
  warnOrSetInactive: () => void
  cancel: () => void
  showWarning: boolean
}

function useDelayedMount(isActive: boolean) {
  const [mount, setMount] = useState(isActive)
  useEffect(() => {
    const t = setTimeout(() => setMount(isActive), isActive ? 20 : 100)
    return () => {
      clearTimeout(t)
    }
  }, [isActive])

  return mount
}

type DrawerSize = "small" | "medium" | "large"

const DrawerSizes = {
  small: 440,
  medium: 600,
  large: 1680,
}

function clipWidth(x: number) {
  return Math.min(x, document.body.clientWidth - 20)
}

type Props = {
  isActive: boolean
  setInactive: () => void
  size: DrawerSize
  warnOnCloseProps: WarnOnCloseResponse
  rootId?: string
  children: ReactNode
}

export default function Drawer({
  isActive,
  setInactive,
  size,
  warnOnCloseProps,
  rootId,
  children,
}: Props) {
  const sz = useScreenSize()
  const mount = useDelayedMount(isActive)

  const thisSize = clipWidth(DrawerSizes[size ?? "small"])
  const { topDrawerWidth, setTopDrawerWidth } = useCompassContext()
  useEffect(() => {
    if (isActive) setTopDrawerWidth?.(thisSize)
  }, [setTopDrawerWidth, isActive, thisSize])

  const el = document.getElementById(rootId ?? "mos-drawer-root") ?? document.body
  const mobileDrawerHeight = useGeometry(el)?.h ?? document.body.clientHeight

  return (
    <BaseDrawer
      open={isActive}
      onClose={(e) => {
        warnOnCloseProps.warnOrSetInactive()
        e.stopPropagation()
      }}
      width={thisSize}
      push={topDrawerWidth ? { distance: topDrawerWidth - thisSize + 20 } : undefined}
      closeIcon={null}
      placement={sz === "sm" ? "bottom" : "right"}
      zIndex={10000}
      height={sz === "sm" ? mobileDrawerHeight : undefined}
      rootStyle={{ isolation: "isolate", position: "absolute", top: 0, bottom: 0 }}
      headerStyle={{ display: "none" }}
      drawerStyle={{ background: "transparent" }}
      bodyStyle={{ padding: 0, background: "white" }}
      maskStyle={{ background: "transparent" }}
      contentWrapperStyle={{
        overflow: "hidden",
        ...(sz !== "sm" && {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        }),
      }}
      destroyOnClose
    >
      <LayoutFlagContextProvider disableCardsListPadding={false}>
        <div className="isolate h-full">{mount ? children : null}</div>
      </LayoutFlagContextProvider>
      {warnOnCloseProps.showWarning && (
        <WarningOverlay
          closeForm={() => {
            setInactive()
            warnOnCloseProps.cancel()
          }}
          dismissWarning={warnOnCloseProps.cancel}
        />
      )}
    </BaseDrawer>
  )
}
